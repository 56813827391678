import React from "react";
import Carousel from "react-multi-carousel";
import EnlargingPhoto from "../EnlargingPhoto";

const carouselResponsive = {
  xl: {
    breakpoint: {
      max: 3000,
      min: 1450,
    },
    items: 5,
    slidesToSlide: 5,
  },

  l: {
    breakpoint: {
      max: 1450,
      min: 1200,
    },
    items: 4,
    slidesToSlide: 4,
  },
  m: {
    breakpoint: {
      max: 1200,
      min: 950,
    },
    items: 3,
    slidesToSlide: 3,
  },
  s: {
    breakpoint: {
      max: 950,
      min: 700,
    },
    items: 2,
    slidesToSlide: 2,
  },
  xs: {
    breakpoint: {
      max: 700,
      min: 0,
    },
    items: 1,
    slidesToSlide: 1,
  },
};

const PhotoGallery = ({ images }) => {
  return (
    <Carousel
      arrows
      draggable
      swipeable
      showDots
      responsive={carouselResponsive}
    >
      {images.map(({ src, caption }, index) => (
        <EnlargingPhoto key={`${src}-${index}`} src={src} caption={caption} />
      ))}
    </Carousel>
  );
};

export default PhotoGallery;
