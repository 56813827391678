import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Meta from "../ui-components/Meta";
import { useDispatch, useSelector } from "react-redux";
import {
  selectBasketItems,
  selectBasketTotal,
  updateBasket,
  removeBasketItem,
  addPurchasedAlerts,
  setLastOrderAlerts,
} from "../store/basket.slice";
import { Table } from "@redwigwam/redalerts.table";
import DeleteIconOutlined from "@material-ui/icons/DeleteOutline";
import styled from "styled-components";
import { setGlobalError } from "../store/error.slice";
import { Button } from "@redwigwam/redalerts.elements.button";
import { Paper } from "@redwigwam/redalerts.elements.paper";
import { TextInput } from "@redwigwam/redalerts.fields.text";

import GroupOutlinedIcon from "@material-ui/icons/GroupOutlined";
import SummaryItem from "../ui-components/Summary/SummaryItem";
import { CircularProgress } from "@material-ui/core";
import { BackButton } from "../ui-components/Buttons";
import dataService from "../api/data.service";
import { formatCurrency, formatDate, formatROI } from "../utils/text";

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;
const StyledTerms = styled.a`
  color: ${(props) => props.theme.colours.purple};
  cursor: pointer;
  font-weight: 600;
`;

const StyledAgreement = styled.p`
  font-size: 12px;
  max-width: 255px;
  margin: 0 16px 0 0;
`;

const OrderSummaryWrap = styled.section`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-grow: 1;
  margin-top: 16px;
  margin-right: 8px;
`;
const OrderSummary = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  background-color: #e7cce2;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 12px 24px;
`;

const Heading = styled.h2`
  margin: 0 0 16px 0;
  font-weight: 600;
`;

const DeleteButton = styled(Button)`
  padding: 8px;
  margin: 0;
  height: unset;
  display: block;
`;

const StyledTextInput = styled(TextInput)`
  fieldset {
    border-color: ${(props) => props?.theme?.colours?.purple};
  }
`;

const TableContainer = styled(Paper)`
  margin: 24px 8px 8px;
`;

const Checkout = () => {
  const [loading, setLoading] = useState(false);
  const [poNumber, setPoNumber] = useState("");
  const dispatch = useDispatch();
  const history = useHistory();
  const items = useSelector(selectBasketItems);
  const basketTotal = useSelector(selectBasketTotal);

  const handlePoChange = (e) => {
    setPoNumber(e.target.value);
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const Alerts = Object.keys(items);
      const body = { Alerts, PoNumber: poNumber };

      const response = await dataService.createOrder(body);
      if (response?.data?.Success) {
        dispatch(updateBasket([]));
        dispatch(addPurchasedAlerts(Alerts));
        dispatch(setLastOrderAlerts(items));
        history.push("/confirmation");
      } else {
        const message = `Failed to create order (${response?.data?.Message})`;
        dispatch(setGlobalError(message));
      }
      setLoading(false);
    } catch (e) {
      console.error(e);
      dispatch(setGlobalError(e.message));
      setLoading(false);
    }
  };
  const columns = [
    { accessor: "Retailer", Header: "Retailer", flex: 1, disableFilters: true },
    { accessor: "AlertType", Header: "Type", flex: 1, disableFilters: true },
    {
      accessor: "StoreName",
      Header: "Store",
      flex: 1,
    },
    {
      accessor: "Product",
      Header: "Product",
      Cell: (row) => row?.value?.replace?.(/\s+/, " "),
      flex: 1,
    },
    { accessor: "Sku", Header: "SKU", flex: 1 },

    {
      accessor: "AlertValue",
      Header: "Value",
      Cell: (row) => formatCurrency(row.value),
      flex: 1,
      disableFilters: true,
    },
    {
      accessor: "AnticipatedROI",
      Header: "Anticipated ROI",
      Cell: (row) => formatROI(row.value),
      flex: 1,
      disableFilters: true,
    },

    {
      accessor: "AlertCost",
      Header: "Cost",
      Cell: (row) => formatCurrency(row.value),
      flex: 1,
      disableFilters: true,
    },

    {
      accessor: "CreatedDate",
      Header: "Date Identified",
      Cell: (row) => formatDate(row.value),
      flex: 1,
      disableFilters: true,
    },
  ];

  return (
    <PageContainer>
      <Meta title="Checkout" />
      <div>
        <BackButton text="Amend" />
      </div>

      <TableContainer>
        <Heading>
          Confirm the tasks you want the redwigwam field team to complete
        </Heading>
        <Table
          data={Object.values(items)}
          columns={columns}
          loading={items.length === 0}
          enableSorting
          enableFiltering
        />
      </TableContainer>

      <OrderSummaryWrap>
        <OrderSummary>
          <StyledAgreement>
            By purchasing you are agreeing to our{" "}
            <StyledTerms
              href="https://portal.redwigwam.com/terms"
              target="_blank"
            >
              terms and conditions
            </StyledTerms>
          </StyledAgreement>

          <StyledTextInput
            name="po-number"
            onChange={handlePoChange}
            label="Your PO ref (optional)"
            value={poNumber}
          />
          <SummaryItem text="Total" value={formatCurrency(basketTotal)} />
          <Button
            primary
            onClick={handleSubmit}
            startIcon={
              loading ? (
                <CircularProgress color="white" size="24px" />
              ) : (
                <GroupOutlinedIcon />
              )
            }
            disabled={loading}
            text="Purchase"
          />
        </OrderSummary>
      </OrderSummaryWrap>
    </PageContainer>
  );
};

export default Checkout;
