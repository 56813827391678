import React from "react";
import styled from "styled-components";
import { formatCurrency } from "../../utils/text";

const StyledOrderTable = styled.table`
  th,
  td {
    padding-right: ${(props) => props.theme.spacing(1)}px;

    .not-set {
      font-weight: normal;
    }
  }
  th {
    font-weight: 600;
    width: 200px;
    text-align: left;
    font-size: 12px;
  }
  td {
    color: #89016f;
    font-size: 18px;
  }
`;

const PurchaseOrderTable = ({ number, cost, date, roi }) => {
  const prettyDate = new Date(date).toLocaleDateString();
  return (
    <StyledOrderTable>
      <thead>
        <tr>
          <th>Purchase Order</th>
          <th>Cost</th>
          <th>Anticipated ROI</th>
          <th>Date Ordered</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{number ? number : <i className="not-set">not set</i>}</td>
          <td>{cost ? `£${parseFloat(cost).toFixed(2)}` : "?"}</td>
          <td>{roi ? formatCurrency(roi) : "?"} </td>
          <td>{date ? prettyDate : "?"}</td>
        </tr>
      </tbody>
    </StyledOrderTable>
  );
};

export default PurchaseOrderTable;
