import React, { useEffect, useState } from "react";

import Meta from "../ui-components/Meta";
import styled, { useTheme } from "styled-components";
import { Table } from "@redwigwam/redalerts.table";
import { useHistory, useParams } from "react-router-dom";
import { Button } from "@redwigwam/redalerts.elements.button";
import { Paper } from "@redwigwam/redalerts.elements.paper";
import { Pie } from "@redwigwam/redalerts.charts.pie";
import { Bar } from "@redwigwam/redalerts.charts.bar";

import SummaryItem from "../ui-components/Summary/SummaryItem";

import PhotoLibraryOutlinedIcon from "@material-ui/icons/PhotoLibraryOutlined";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import HourglassEmptyOutlinedIcon from "@material-ui/icons/HourglassEmptyOutlined";

import photoEvidenceImage from "../images/photoevidence.png";
import { BackButton } from "../ui-components/Buttons";
import dataService from "../api/data.service";
import { formatCurrency, formatROI } from "../utils/text";

const ChartContainer = styled.div`
  display: flex;
  > * {
    flex-grow: 1;
  }
`;

const BookingStatusWrap = styled(Paper)`
  min-width: calc(40% - 16px);
`;

const InterventionWrap = styled(Paper)`
  min-width: calc(40% - 16px);
`;

const PhotoEvidenceWrap = styled(Paper)`
  display: flex;
  flex-direction: column;
`;

const PhotoEvidenceImage = styled.div`
  background-image: url(${photoEvidenceImage});
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
`;

const OrderSummaryContainer = styled.div`
  display: flex;
  justify-content: start;
  background-color: #e9e7e7;
  border-radius: 8px;
`;

const SlimButton = styled(Button)`
  margin: 0;
  padding: 0;
  display: block;
  height: unset;
  font-size: inherit;
`;

const StatusWrap = styled.div`
  display: flex;
  align-items: center;
  > svg {
    margin-right: 8px;
  }
`;

const StyledSummaryItem = styled(SummaryItem)`
  margin-right: 72px;
`;

const ProcessingWrap = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
  > svg {
    width: 2em;
    height: 2em;
    color: ${(props) => props?.theme?.colours?.purple};
  }
`;

const ChartWrap = styled.div`
  max-width: 500px;
  margin: 0 auto;
`;

const ExportButton = styled(Button)`
  align-self: center;
`;

const Order = () => {
  const [details, setDetails] = useState({
    loading: true,
    error: false,
    data: {},
  });

  const [statusSummary, setStatusSummary] = useState({
    loading: true,
    error: false,
    data: [],
  });

  const [interventionSummary, setInterventionSummary] = useState({
    loading: true,
    error: false,
    data: [],
  });

  const history = useHistory();
  const theme = useTheme();

  const StatusColourMap = new Map();
  StatusColourMap.set("draft", theme.colours.grey);
  StatusColourMap.set("not yet booked", theme.colours.pink);
  StatusColourMap.set("confirmed booked", theme.colours.orange);
  StatusColourMap.set("started", theme.colours.red);
  StatusColourMap.set("completed", theme.colours.green);

  const defaultColour = "lightgrey";

  const { orderId } = useParams();

  const handlePhotoReport = () => {
    history.push(`/OrderHistory/${orderId}/Photos`);
  };

  useEffect(() => {
    const getData = async () => {
      setDetails((prev) => ({ ...prev, loading: true }));
      setStatusSummary((prev) => ({ ...prev, loading: true }));

      dataService
        .getOrderDetail(orderId)
        .then((response) => {
          setDetails({ data: response?.data, loading: false, error: false });
        })
        .catch((e) => {
          console.error(e);
          setDetails((prev) => ({
            ...prev,
            loading: false,
            error: "Unable to fetch data",
          }));
        });

      dataService
        .getOrderStatusSummary(orderId)
        .then((response) => {
          setStatusSummary({
            data: response?.data,
            loading: false,
            error: false,
          });
        })
        .catch((e) => {
          console.error(e);
          setStatusSummary((prev) => ({
            ...prev,
            loading: false,
            error: "Unable to fetch data",
          }));
        });

      dataService
        .getOrderInterventionSummary(orderId)
        .then((response) => {
          setInterventionSummary({
            data: response?.data,
            loading: false,
            error: false,
          });
        })
        .catch((e) => {
          console.error(e);
          setInterventionSummary((prev) => ({
            ...prev,
            loading: false,
            error: "Unable to fetch data",
          }));
        });
    };

    getData();
  }, [orderId]);

  const handleClick = (alertId) => {
    history.push(`/OrderHistory/${orderId}/Alert/${alertId}`);
  };

  const columns = [
    { accessor: "Retailer", Header: "Retailer", flex: 1, disableFilters: true },
    { accessor: "AlertType", Header: "Type", flex: 1, disableFilters: true },
    { accessor: "StoreName", Header: "Store", flex: 1 },

    {
      accessor: "ProductName",
      Header: "Product",
      Cell: (row) => row?.value?.replace(/\s+/, " "),
      flex: 1,
    },
    { accessor: "Sku", Header: "SKU", flex: 1 },

    {
      accessor: "AlertValue",
      Header: "Value",
      Cell: (row) => formatCurrency(row.value),
      flex: 1,
      disableFilters: true,
    },
    {
      accessor: "AnticipatedROI",
      Header: "Anticipated ROI",
      Cell: (row) => formatROI(row.value),
      flex: 1,
      disableFilters: true,
    },

    {
      accessor: "AlertCost",
      Header: "Cost",
      Cell: (row) => formatCurrency(row.value),
      flex: 1,
      disableFilters: true,
    },
    {
      accessor: "StatusName",
      Header: "Status",
      Cell: (cell) => {
        return (
          <StatusWrap>
            <FiberManualRecordIcon
              htmlColor={
                StatusColourMap.get(cell.value.toLowerCase()) || defaultColour
              }
            />
            {cell.value}
          </StatusWrap>
        );
      },
      disableFilters: true,
    },
    {
      Header: " ",
      accessor: "Id",
      Cell: (cell) => (
        <SlimButton
          onClick={() => handleClick(cell.value)}
          text="View Summary"
        />
      ),

      disableSortBy: true,
      disableFilters: true,
    },
  ];

  const { OrderNumber, Ponumber, TotalCost, DateOrdered } =
    details?.data?.Order ?? {};

  const hasCompletedTask =
    details?.data?.Alerts?.some?.((alert) => alert?.Status === 5) ?? false;

  const handleExport = () => {
    const fileName = `Order_${OrderNumber}__${new Date(
      DateOrdered
    ).toLocaleDateString()}.zip`;
    dataService.getOrderSummaryExport(orderId, fileName);
  };

  return (
    <>
      <Meta title="Order" />
      <BackButton />
      <Paper>
        <h2>Summary</h2>
        <OrderSummaryContainer>
          <StyledSummaryItem
            text="Order Number"
            value={details?.loading ? "-" : OrderNumber}
          />
          <StyledSummaryItem
            text="Reference"
            value={
              details?.loading
                ? "-"
                : Ponumber === "" || !Ponumber
                ? "-"
                : Ponumber
            }
          />
          <StyledSummaryItem
            text="Order Value"
            value={details?.loading ? "-" : formatCurrency(TotalCost)}
          />
          <StyledSummaryItem
            text="Order Date"
            value={
              details?.loading
                ? "-"
                : new Date(DateOrdered).toLocaleDateString()
            }
          />
          <StyledSummaryItem
            text="Tasks Purchased"
            value={details?.data?.Alerts?.length}
          />
          <ExportButton
            onClick={handleExport}
            text="Export Results"
            secondary
            disabled={!hasCompletedTask}
          />
        </OrderSummaryContainer>
      </Paper>
      {details?.data?.Alerts?.length === 0 ? (
        <Paper>
          <ProcessingWrap>
            <HourglassEmptyOutlinedIcon />
            <p>
              This order is currently being processed, please check back later
            </p>
          </ProcessingWrap>
        </Paper>
      ) : (
        <>
          <ChartContainer>
            <BookingStatusWrap>
              <h2>Booking Status</h2>
              <ChartWrap>
                <Pie
                  data={statusSummary?.data ?? []}
                  dataKey="Total"
                  nameKey="KeyName"
                  error={statusSummary?.error}
                  loading={statusSummary?.loading}
                  colourMap={StatusColourMap}
                  defaultColour={defaultColour}
                  height={250}
                />
              </ChartWrap>
            </BookingStatusWrap>
            {interventionSummary?.data?.length > 0 && (
              <InterventionWrap>
                <h2>Interventions</h2>

                <ChartWrap>
                  <Bar
                    data={interventionSummary?.data ?? []}
                    dataKey="Count"
                    nameKey="Outcome"
                    vertical
                    yAxisWidth={200}
                    yAxisAnchor="end"
                    chartHeight={250}
                    loading={interventionSummary?.loading}
                    error={interventionSummary?.error}
                  />
                </ChartWrap>
              </InterventionWrap>
            )}
            {hasCompletedTask && (
              <PhotoEvidenceWrap>
                <h2>Photographic Evidence</h2>
                <PhotoEvidenceImage>
                  <Button
                    text="View Report"
                    secondary
                    startIcon={<PhotoLibraryOutlinedIcon />}
                    onClick={handlePhotoReport}
                  />
                </PhotoEvidenceImage>
              </PhotoEvidenceWrap>
            )}
          </ChartContainer>
          <Paper>
            <Table
              columns={columns}
              data={details?.data?.Alerts}
              loading={details?.loading}
              error={details?.error}
              enableSorting
              enableFiltering
            />
          </Paper>
        </>
      )}
    </>
  );
};

export default Order;
