import React, { useCallback, useEffect, useState, useMemo } from "react";
import { useHistory } from "react-router-dom";
import Meta from "../ui-components/Meta";
import AddShoppingCartOutlinedIcon from "@material-ui/icons/AddShoppingCartOutlined";
import AccountBalanceWalletOutlinedIcon from "@material-ui/icons/AccountBalanceWalletOutlined";
import { Button } from "@redwigwam/redalerts.elements.button";
import { TextInput } from "@redwigwam/redalerts.fields.text";

import { Table } from "@redwigwam/redalerts.table";
import { useDispatch, useSelector } from "react-redux";
import {
  selectBasketItems,
  selectBasketTotal,
  selectPurchasedAlerts,
  updateBasket,
  selectBudget,
  updateBudget,
  selectBasketAnticipatedROI,
} from "../store/basket.slice";
import styled from "styled-components";
import { selectRehydrated } from "../store";
import { Paper } from "@redwigwam/redalerts.elements.paper";
import SummaryItem from "../ui-components/Summary/SummaryItem";
import dataService from "../api/data.service";
import { getFilterInfoItems, getSortInfoItems } from "../utils/table";
import { formatCurrency, formatDate, formatROI } from "../utils/text";

const HomepageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

const Heading = styled.h2`
  margin: 0 0 16px 0;
  font-weight: 600;
`;
const BudgetFieldWrap = styled.div`
  margin: ${(props) => props.theme.spacing(1)}px 0;
  display: flex;
  justify-content: flex-start;
`;

const OrderSummaryWrap = styled.section`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-grow: 1;
  margin-top: 16px;
  margin-right: 8px;
`;
const OrderSummary = styled.div`
  display: flex;
  justify-content: flex-end;

  background-color: #e7cce2;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 12px 24px;
`;

const Home = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [data, setData] = useState([]);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [forcedRefresh, setForcedRefresh] = useState(false);

  const initialSortData = [
    {
      id: "AnticipatedROI",
      desc: true,
    },
  ];

  const [sortData, setSortData] = useState(initialSortData);
  const [filterData, setFilterData] = useState([]);
  const [selectedRows, setSelectedRows] = useState({});
  const [basketInitialised, setBasketInitialised] = useState(false);

  const basket = useSelector(selectBasketItems);
  const budget = useSelector(selectBudget);

  const rehydrated = useSelector(selectRehydrated);
  const basketTotal = useSelector(selectBasketTotal);
  const anticipatedROI = useSelector(selectBasketAnticipatedROI);

  const purchasedAlerts = useSelector(selectPurchasedAlerts);

  const forceRefresh = () => {
    setForcedRefresh((prev) => !prev);
  };

  const reset = () => {
    setError(false);
    setFilterData([]);
    setSelectedRows({});
    setSortData(initialSortData);
  };

  const handleBudgetChange = (e) => {
    if (
      Object.keys(selectedRows).length > 0 &&
      !budget &&
      !window.confirm(
        "Setting a budget will clear previous selections, are you sure?"
      )
    ) {
      return;
    }
    dispatch(updateBudget(parseFloat(e?.target?.value)));
  };

  const handleSubmit = () => {
    if (budget && budget - basketTotal < 0) {
      if (window.confirm("Your chosen budget has been exceeded, proceed?")) {
        history.push("/checkout");
      }
    } else {
      history.push("/checkout");
    }
  };

  const getData = useCallback(async () => {
    const queryBody = {
      PageInfo: {
        Page: 1,
        PageSize: 200,
      },
      FilterInfo: {
        Items: getFilterInfoItems(filterData),
        LinkOperator: "string",
      },
      SortInfo: getSortInfoItems(sortData),
    };

    try {
      setLoading(true);

      // call API
      const response = await dataService.getCurrentAlerts(queryBody);

      // set pagination values
      // setPageCount(Math.ceil(response?.data?.NumRows / pageSize));
      const apiResponse = response?.data?.Data;
      const filteredResponse = apiResponse.filter(
        (row) => !purchasedAlerts.includes(row.Id)
      );

      setData(filteredResponse);
      setError(false);
      setLoading(false);
    } catch (e) {
      console.error(e);
      setData([]);
      setError(e.message);
      setLoading(false);
    }
  }, [sortData, filterData, purchasedAlerts]);

  useEffect(() => {
    if (rehydrated) {
      getData();
    }
  }, [rehydrated, getData, forcedRefresh]);

  useEffect(() => {
    if (basketInitialised) {
      // not intial call, update based on budget
      let chosenSum = 0;
      let updatedSelectedRows = {};
      data.forEach((item) => {
        const { AlertCost, Id } = item;
        if (chosenSum + AlertCost <= budget && !updatedSelectedRows[Id]) {
          // auto select alerts based on budget remaining
          chosenSum += AlertCost;
          updatedSelectedRows[Id] = item;
        }
      });
      setSelectedRows(updatedSelectedRows);
    } else {
      // initial call, selected rows based on redux basket
      setSelectedRows(basket);
    }
    setBasketInitialised(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [budget]);

  useEffect(() => {
    // update redux basket for persisted basket state between pages
    if (basketInitialised && !loading) {
      let newBasket = { ...basket };

      // remove unselected rows from basket
      Object.keys(newBasket).forEach((id) => {
        if (!selectedRows?.[id]) {
          delete newBasket[id];
        }
      });

      // add new items to basket
      data.forEach((item) => {
        if (selectedRows[item.Id]) {
          newBasket[item.Id] = item;
        }
      });

      dispatch(updateBasket(newBasket));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRows]);

  const columnsMemo = useMemo(
    () => [
      {
        accessor: "Retailer",
        Header: "Retailer",
        flex: 1,
        disableFilters: true,
      },
      { accessor: "AlertType", Header: "Type", flex: 1, disableFilters: true },
      {
        accessor: "StoreName",
        Header: "Store",
        flex: 1,
      },
      {
        accessor: "Product",
        Header: "Product",
        Cell: (row) => row.value.replace(/\s+/, " "),
        flex: 1,
      },
      { accessor: "Sku", Header: "SKU", flex: 1 },

      {
        accessor: "AlertValue",
        Header: "Value",
        Cell: (row) => formatCurrency(row.value),
        flex: 1,
        disableFilters: true,
      },
      {
        accessor: "AnticipatedROI",
        Header: "Anticipated ROI",
        Cell: (row) => formatROI(row.value),
        flex: 1,
        disableFilters: true,
      },

      {
        accessor: "AlertCost",
        Header: "Cost",
        Cell: (row) => formatCurrency(row.value),
        flex: 1,
        disableFilters: true,
      },

      {
        accessor: "CreatedDate",
        Header: "Date Identified",
        Cell: (row) => formatDate(row.value),
        flex: 1,
        disableFilters: true,
      },
    ],
    []
  );

  const dataMemo = React.useMemo(() => data, [data]);

  return (
    <HomepageContainer>
      <Meta title="Home" />

      <BudgetFieldWrap>
        <Paper>
          <Heading>Please enter your budget</Heading>
          <TextInput
            type="number"
            label="Budget"
            name="budget"
            onChange={handleBudgetChange}
            icon={<AccountBalanceWalletOutlinedIcon />}
            value={budget ?? ""}
            min={0}
            step={50}
          />
        </Paper>
      </BudgetFieldWrap>

      <Paper>
        <Heading>
          Select the tasks you want the redwigwam field team to complete
        </Heading>
        <Table
          data={dataMemo}
          columns={columnsMemo}
          enableSelection
          onSelectedRowsChange={setSelectedRows}
          loading={loading}
          error={error}
          enableSorting
          enableFiltering
          controlledSelectedRows={selectedRows}
          onFilter={setFilterData}
          onSort={setSortData}
          serverSideFiltering
          serverSideSorting
          idField="Id"
          noDataOverlayAction={forceRefresh}
          noDataOverlayText="No alerts available currently"
          errorOverlayAction={reset}
        />
      </Paper>
      <OrderSummaryWrap>
        <div>
          <OrderSummary>
            <SummaryItem
              text="Tasks selected"
              value={`${Object.keys(selectedRows)?.length ?? 0} / ${
                dataMemo?.length
              }`}
            />
            <SummaryItem
              text="Cost of selected tasks"
              value={formatCurrency(basketTotal)}
            />
            <SummaryItem
              text="Budget remaining"
              value={`${budget ? formatCurrency(budget - basketTotal) : "N/A"}`}
            />
            <SummaryItem
              text="Anticipated ROI"
              value={`${
                anticipatedROI ? formatCurrency(anticipatedROI) : "N/A"
              }`}
            />

            <Button
              primary
              onClick={handleSubmit}
              startIcon={<AddShoppingCartOutlinedIcon />}
              disabled={Object.keys(selectedRows).length === 0}
              text="Add to tasks checkout"
            ></Button>
          </OrderSummary>
        </div>
      </OrderSummaryWrap>
    </HomepageContainer>
  );
};

export default Home;
