import { CircularProgress } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Switch, Route, Redirect } from "react-router-dom";
import { getToken, silentRefresh } from "./api/auth";
import styled from "styled-components";
import Login from "./pages/Login";
import { authenticateUser, selectIsAuthenticated } from "./store/auth.slice";

// Pages

import PageLayout from "./ui-components/PageLayout";
import routes from "./utils/routes";

const Loading = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  svg {
    color: ${(props) => props?.theme?.colours?.purple};
  }
`;

const App = () => {
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const [authInitComplete, setAuthInitComplete] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const checkIfAlreadyLoggedIn = async () => {
      console.log("initial silent refresh");
      await silentRefresh(dispatch);
      setAuthInitComplete(true);

      const { token } = getToken();

      if (token) {
        dispatch(authenticateUser(true));
      }
    };

    checkIfAlreadyLoggedIn();
  }, [dispatch]);

  return (
    <>
      {authInitComplete ? (
        isAuthenticated ? (
          <Pages />
        ) : (
          <Login />
        )
      ) : (
        <Loading>
          <CircularProgress />
        </Loading>
      )}
    </>
  );
};

const Pages = () => {
  return (
    <Switch>
      {routes.map(({ path, page: PageToRender, exact, title }) => (
        <Route path={path} key={path} exact={exact}>
          <PageLayout title={title}>
            <PageToRender />
          </PageLayout>
        </Route>
      ))}
      <Route path="*">
        <Redirect to="/" />
      </Route>
    </Switch>
  );
};

export default App;
