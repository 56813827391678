import React from "react";
import styled from "styled-components";

const SummaryItemWrap = styled.div`
  margin: 8px 24px;
`;

const SummaryItemText = styled.p`
  font-size: 12px;
  margin: 0;
  font-weight: 600;
`;
const SummaryItemValue = styled.p`
  font-size: 18px;
  color: ${(props) => props.theme.colours.purple};
  margin: 6px 0 0 0;
`;

const SummaryItem = ({ className, text, value }) => {
  return (
    <SummaryItemWrap className={className}>
      <SummaryItemText>{text}</SummaryItemText>
      <SummaryItemValue>{value}</SummaryItemValue>
    </SummaryItemWrap>
  );
};

export default SummaryItem;
