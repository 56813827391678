export const getFilterInfoItems = (filterData) => {
  const filterInfo = filterData?.map(({ id, value }, index) => ({
    ColumnField: id,
    Id: index,
    OperatorValue: "contains",
    Value: value,
  }));

  return filterInfo;
};
export const getSortInfoItems = (sortData) => {
  const filterInfo = sortData?.map(({ id, desc, asc }, index) => ({
    Field: id,
    sort: desc ? "desc" : "asc",
  }));

  return filterInfo;
};
