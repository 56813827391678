import { CloseOutlined } from "@material-ui/icons";
import React from "react";
import { createPortal } from "react-dom";
import styled from "styled-components";

const PortalContainer = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
  background-color: #383838ee;
`;

const ModalPosition = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const ContentWrap = styled.div`
  padding: 16px;
  position: relative;
  background: white;
  border-radius: 8px;
  width: 75%;
  max-width: 90vh;
  aspect-ratio: 1/1;
`;

const CloseButton = styled(CloseOutlined)`
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
`;

const Modal = ({ children, open, setOpen, rootId = "modal-root" }) => {
  const root = document.getElementById(rootId);

  if (!root) {
    console.error("couldn't find rootId in DOM", rootId);
    return null;
  }

  return createPortal(
    <>
      {open && (
        <PortalContainer>
          <ModalPosition>
            <ContentWrap>
              <CloseButton onClick={() => setOpen(false)} />
              {children}
            </ContentWrap>
          </ModalPosition>
        </PortalContainer>
      )}
    </>,
    root
  );
};

export default Modal;
