import React, { useState } from "react";
import styled from "styled-components";
import Modal from "../Modal";

const Photo = styled.img`
  width: 240px;
  height: 240px;
  border-radius: 8px;
  background-color: lightgrey;
  margin: 8px;
`;

const LargePhoto = styled.img`
  margin: 0 auto;
  display: block;
  height: calc(100% - 1em - 8px);
`;

const Caption = styled.p`
  margin: 4px;
  text-align: center;
`;

const Wrap = styled.div`
  text-align: center;
`;

const EnlargingPhoto = ({ src, caption }) => {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  return (
    <Wrap>
      <Photo src={src} onClick={handleClick} />

      {caption && <Caption>{caption}</Caption>}
      <Modal open={open} setOpen={setOpen}>
        <LargePhoto src={src} />
        {caption && <Caption>{caption}</Caption>}
      </Modal>
    </Wrap>
  );
};

export default EnlargingPhoto;
