export const captilizeText = (text) => {
  if (typeof text === "string") {
    return text
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  } else {
    return null;
  }
};

export const formatDateWithTime = (date) => {
  return new Date(date).toLocaleDateString("en-gb", {
    weekday: "short",
    day: "numeric",
    month: "short",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  });
};

export const formatDate = (date) => {
  return new Date(date).toLocaleDateString("en-gb");
};

export const formatCurrency = (text) => {
  return `£ ${parseFloat(text).toFixed(2) || "???"}`;
};

export const formatROI = (text) => {
  return `${parseFloat(text).toFixed(2) || "???"} x`;
};
