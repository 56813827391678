import React from "react";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";

const StyledLink = styled(Link)`
  position: relative;
  text-decoration: none;
  z-index: 10;
  transform: translateX(-4px);
`;

const NavIcon = styled.div`
  display: flex;
  align-items: center;
  color: white;
  opacity: 0.5;
  padding: 14px;
  margin-bottom: 6px;
  font-size: 24px;
  line-height: 24px;

  &.active,
  :hover {
    background-color: #a0338b;
    border-radius: 8px;
    opacity: 1;
  }
  :hover {
    .nav-title {
      display: block;
    }
  }
`;

const NavTitle = styled.p`
  display: none;
  margin: 0;
  font-size: 16px;
  white-space: nowrap;
  overflow: hidden;
  padding-left: 8px;
  font-family: "Montserrat", sans-serif;
`;

const NavLink = ({ path, icon, title, onClick }) => {
  const { pathname } = useLocation();

  return (
    <StyledLink to={path} onClick={onClick}>
      <NavIcon className={`${pathname === path ? "active" : ""}`}>
        {icon}
        <NavTitle className="nav-title">{title}</NavTitle>
      </NavIcon>
    </StyledLink>
  );
};

export default NavLink;
