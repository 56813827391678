import React, { useState } from "react";

import { Button } from "@redwigwam/redalerts.elements.button";
import { TextInput } from "@redwigwam/redalerts.fields.text";
import { Paper } from "@redwigwam/redalerts.elements.paper";
import Meta from "../ui-components/Meta";
import { Alert } from "@material-ui/lab";

import LockIcon from "@material-ui/icons/LockOutlined";
import PersonIcon from "@material-ui/icons/PersonOutlined";

import { useDispatch } from "react-redux";
import styled from "styled-components";
import { login } from "../api/auth";

import logo from "../images/red-alerts-logo.png";
import backgroundImage from "../images/loginbackground.jpg";

import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { setUserData } from "../store/user.slice";
import dataService from "../api/data.service";
import { useHistory } from "react-router";

const Background = styled.div`
  position: absolute;
  background-image: url(${backgroundImage});
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
`;

const StyledForgottenPassword = styled.p`
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  > a {
    text-decoration: none;
    color: ${(props) => props?.theme?.colours?.purple};
  }
`;

const LogoContainer = styled.div`
  background-color: #f4f3f3;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  padding: 24px 0;
`;
const RedAlertsLogo = styled.img`
  width: 200px;
`;

const StyledForm = styled.form`
  padding: 24px;
`;

const StyledPaper = styled(Paper)`
  max-width: 500px;
  margin: 48px auto 0 auto;
  padding: 0;
`;

const FormHeading = styled.div`
  text-align: center;
  h1 {
    color: ${(props) => props?.theme?.colours?.purple};
    font-weight: 600;
  }
`;

const StyledButton = styled(Button)`
  margin: 16px 0;
`;

const ErrorWrapper = styled.div`
  margin: 0 10px 10px;
`;

const ErrorMessage = styled.span`
  color: red;
  font-size: 14px;
`;

const Login = () => {
  const [formInput, setFormInput] = useState({ username: "", password: "" });
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const response = await dataService.login(formInput);

      if (response?.data?.Success) {
        const { Token: token, TokenExpireTime: expiry } =
          response?.data?.ObjData ?? {};

        const expiryInMillis = expiry * 1000;

        login(token, expiryInMillis, dispatch);

        history.push("/");

        const userDataResponse = await dataService.getUserDetails();

        dispatch(setUserData(userDataResponse.data));
      } else {
        setError(response?.data?.Message);
      }

      setLoading(false);
    } catch (e) {
      console.error(e);
      setError(e.message);
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setFormInput({ ...formInput, [name]: value });
  };

  return (
    <Background>
      <Meta title="Login" />

      <StyledPaper>
        <LogoContainer>
          <RedAlertsLogo src={logo} alt="Red Alerts Logo" />
        </LogoContainer>
        <StyledForm onSubmit={handleSubmit}>
          <FormHeading>
            <h1>Login</h1>
            <p>Enter your details below to continue</p>
          </FormHeading>

          {error && (
            <ErrorWrapper>
              <ErrorMessage>
                The email and password you entered did not match our records.
                Please try again.
              </ErrorMessage>
            </ErrorWrapper>
          )}
          <TextInput
            type="email"
            label="Email Address"
            name="username"
            required
            value={formInput.username}
            onChange={handleChange}
            icon={<PersonIcon />}
          />
          <TextInput
            type="password"
            label="Password"
            name="password"
            required
            value={formInput.password}
            onChange={handleChange}
            icon={<LockIcon />}
          />

          <StyledButton
            primary
            type="submit"
            disabled={loading}
            endIcon={<ArrowForwardIcon />}
            text="Login"
            fullWidth
          />
          <StyledForgottenPassword>
            <a
              href="https://portal.redwigwam.com/hirers/forgot-password"
              target="_blank"
              rel="noreferrer"
            >
              Forgotten details?
            </a>
          </StyledForgottenPassword>
        </StyledForm>
      </StyledPaper>
    </Background>
  );
};

export default Login;
