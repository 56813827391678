import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  globalError: null,
};

export const errorSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setGlobalError: (state, action) => {
      state.globalError = action.payload ?? null;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setGlobalError } = errorSlice.actions;

export const selectGlobalError = (state) => state?.error?.globalError ?? null;

export default errorSlice.reducer;
