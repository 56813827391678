import React, { useEffect, useState } from "react";
import { Table } from "@redwigwam/redalerts.table";
import { Paper } from "@redwigwam/redalerts.elements.paper";
import { Button } from "@redwigwam/redalerts.elements.button";
import { useHistory } from "react-router-dom";

import Meta from "../ui-components/Meta";
import styled from "styled-components";
import { ArrowForward, ChevronRight } from "@material-ui/icons";
import PurchaseOrderTable from "../ui-components/PurchaseSummaryTable";
import dataService from "../api/data.service";
import { useSelector } from "react-redux";
import { selectUserData } from "../store/user.slice";
import { selectLastOrderAnticaptedROI } from "../store/basket.slice";
import {
  captilizeText,
  formatCurrency,
  formatDate,
  formatDateWithTime,
} from "../utils/text";

const StyledOrderSummary = styled.div`
  padding: ${(props) => props.theme.spacing(3)}px;
  background-color: #ddd;
  border-radius: 10px;
  display: block;
`;

const StyledTable = styled(Table)`
  background-color: white;
`;

const OrderReportButton = styled(Button)`
  margin: 0;
  padding: 0;
  display: block;
  height: unset;
`;

const Header = styled.div`
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 16px;
`;

const OrderSummary = styled(Paper)`
  margin: 8px 8px 24px;
`;

const Confirmation = () => {
  const [orders, setOrders] = useState([]);
  const [error, setError] = useState();
  const [loading, setLoading] = useState(true);

  const userData = useSelector(selectUserData);
  const anticipatedROI = useSelector(selectLastOrderAnticaptedROI);

  const history = useHistory();

  useEffect(() => {
    const getData = async () => {
      try {
        setLoading(true);
        const response = await dataService.getAllOrders({
          pageinfo: {
            PageSize: 10,
            Page: 1,
          },
          SortInfo: [
            {
              Field: "DateOrdered",
              Sort: "desc",
            },
          ],
        });
        setError(false);
        setOrders(response?.data?.Data);
        setLoading(false);
      } catch (e) {
        console.error(e);
        setError(e.message);
        setLoading(false);
      }
    };

    getData();
  }, []);

  const columns = [
    {
      Header: "Date Ordered",
      accessor: "DateOrdered",
      Cell: (cell) => formatDateWithTime(cell.value),
      disableFilters: true,
      flex: 2,
    },
    { Header: "Order Number", accessor: "OrderNumber", flex: 1 },
    { Header: "Purchase Order Ref", accessor: "Ponumber", flex: 1 },
    { Header: "No. of Tasks", accessor: "NumberOfAlerts", flex: 1 },
    {
      Header: "Cost",
      accessor: "TotalCost",
      Cell: (row) => formatCurrency(row.value),
      flex: 1,
    },
    {
      id: "view",
      Cell: (cell) => (
        <OrderReportButton
          text="View Order Report"
          endIcon={<ChevronRight />}
          onClick={() =>
            history.push(`/OrderHistory/${cell.row?.original?.Id}`)
          }
        />
      ),
      width: 210,
    },
  ];

  const [thisOrder, ...otherOrders] = orders;

  return (
    <>
      <Meta title="Order Confirmation" />
      <OrderSummary>
        <Header>
          Thank you for your order
          {userData?.FullName && `, ${captilizeText(userData?.FullName)}`}
        </Header>

        <StyledOrderSummary>
          <h2>Order No. {thisOrder?.OrderNumber}</h2>
          <PurchaseOrderTable
            number={thisOrder?.Ponumber}
            cost={thisOrder?.TotalCost}
            date={thisOrder?.DateOrdered}
            roi={anticipatedROI}
          />
        </StyledOrderSummary>
      </OrderSummary>
      <Paper>
        <Header>Your recent orders</Header>
        <StyledTable
          columns={columns}
          data={orders}
          loading={loading}
          error={error}
        />
        <Button
          text="See All Orders"
          endIcon={<ArrowForward />}
          onClick={() => history.push("/OrderHistory")}
        />
      </Paper>
    </>
  );
};

export default Confirmation;
