import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import basketReducer from "./basket.slice";
import userReducer from "./user.slice";
import errorReducer from "./error.slice";
import authReducer from "./auth.slice";

const reducers = combineReducers({
  basket: basketReducer,
  user: userReducer,
  error: errorReducer,
  auth: authReducer,
});

const rootPersistConfig = {
  key: "root",
  storage: storage,
  blacklist: ["auth", "error"],
};

const persistedReducer = persistReducer(rootPersistConfig, reducers);

export const selectRehydrated = (state) => state?._persist?.rehydrated ?? false;

export default configureStore({
  reducer: persistedReducer,
});
