import { axiosInstance } from "./config";

const REPLACEMENTS = {
  orderId: ":OrderId:",
  alertId: ":AlertId:",
};

export const API_ENDPOINTS = {
  alerts: {
    getCurrentAlerts: "/Alerts/GetCurrentAlerts",
    getAlertSummary: `/Orders/${REPLACEMENTS.orderId}/AlertSummary/${REPLACEMENTS.alertId}`,
  },
  hirer: {
    authenticate: "/Hirer/Authenticate",
    refreshToken: "/Hirer/refresh-token",
  },
  orders: {
    create: "/Orders/CreateOrder",
    getAll: "/Orders/GetAllOrders",
    getOrderDetail: `/Orders/${REPLACEMENTS.orderId}/Detail`,
    getOrderStatusSummary: `/Orders/${REPLACEMENTS.orderId}/StatusSummary`,
    getOrderInterventionSummary: `/Orders/${REPLACEMENTS.orderId}/InterventionSummary`,
    getPhotoSummary: `/Orders/${REPLACEMENTS.orderId}/PhotoSummary`,
    export: `/Orders/${REPLACEMENTS.orderId}/export`,
  },
  user: {
    me: "/me",
  },
};

class DataService {
  getEndpoint(template, replacements) {
    let output = template;
    for (let i = 0; i < replacements.length; i++) {
      const [target, replacement] = replacements[i];
      const regex = new RegExp(`${target}`, "g");
      output = output.replace(regex, replacement);
    }

    return output;
  }

  getOrderDetail(orderId) {
    const endpoint = this.getEndpoint(API_ENDPOINTS.orders.getOrderDetail, [
      [REPLACEMENTS.orderId, orderId],
    ]);

    return axiosInstance.get(endpoint);
  }

  getOrderStatusSummary(orderId) {
    const endpoint = this.getEndpoint(
      API_ENDPOINTS.orders.getOrderStatusSummary,
      [[REPLACEMENTS.orderId, orderId]]
    );

    return axiosInstance.get(endpoint);
  }

  getOrderInterventionSummary(orderId) {
    const endpoint = this.getEndpoint(
      API_ENDPOINTS.orders.getOrderInterventionSummary,
      [[REPLACEMENTS.orderId, orderId]]
    );

    return axiosInstance.get(endpoint);
  }

  getOrderPhotoSummary(orderId) {
    const endpoint = this.getEndpoint(API_ENDPOINTS.orders.getPhotoSummary, [
      [REPLACEMENTS.orderId, orderId],
    ]);

    return axiosInstance.get(endpoint);
  }

  getAlertSummary(orderId, alertId) {
    const endpoint = this.getEndpoint(API_ENDPOINTS.alerts.getAlertSummary, [
      [REPLACEMENTS.orderId, orderId],
      [REPLACEMENTS.alertId, alertId],
    ]);

    return axiosInstance.get(endpoint);
  }

  createOrder(body) {
    return axiosInstance.post(API_ENDPOINTS.orders.create, body);
  }

  getAllOrders(body) {
    return axiosInstance.post(API_ENDPOINTS.orders.getAll, body);
  }

  getCurrentAlerts(body) {
    return axiosInstance.post(API_ENDPOINTS.alerts.getCurrentAlerts, body);
  }

  login(form) {
    return axiosInstance.post(API_ENDPOINTS.hirer.authenticate, form);
  }

  getUserDetails() {
    return axiosInstance.post(API_ENDPOINTS.user.me);
  }

  async getOrderSummaryExport(orderId, fileName) {
    try {
      const endpoint = this.getEndpoint(API_ENDPOINTS.orders.export, [
        [REPLACEMENTS.orderId, orderId],
      ]);

      // make authenticated request for file
      const response = await axiosInstance({
        url: endpoint,
        method: "GET",
        responseType: "blob",
      });

      // convert response to local blob
      const blob = new Blob([response.data]);
      const blobURL = window.URL.createObjectURL(blob);

      //set up link
      const link = document.createElement("a");
      link.href = blobURL;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();

      // tidy up link
      window.URL.revokeObjectURL(blobURL);
      link.remove();
    } catch (e) {
      console.error(e);
      alert(
        "Something went wrong whilst downloading the excport, please try again"
      );
    }
  }
}

const dataService = new DataService();

export default dataService;
