import { createMuiTheme } from "@material-ui/core/styles";
import { rwwTheme } from "@redwigwam/redalerts.theme.theme";

const appTheme = {
  ...rwwTheme,
  overrides: {
    MuiCssBaseline: {
      "@global": {
        "html,body,#root": {
          height: "100%",
        },
        body: {
          backgroundColor: "#fcf5fa",
        },
      },
    },
  },
};

// Create a theme instance.
export const globalTheme = createMuiTheme(appTheme);
