import { Paper } from "@redwigwam/redalerts.elements.paper";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import dataService from "../api/data.service";
import { BackButton } from "../ui-components/Buttons";
import Meta from "../ui-components/Meta";
import PhotoGallery from "../ui-components/PhotoGallery";

const PhotoReport = () => {
  const { orderId } = useParams();

  const [data, setData] = useState([]);

  useEffect(() => {
    dataService.getOrderPhotoSummary(orderId).then((response) => {
      setData(response?.data ?? []);
    });
  }, [orderId]);

  return (
    <>
      <Meta title="Photographic Evidence" />
      <BackButton />

      {data?.length > 0 ? (
        <>
          {data?.map(({ Visit, Photos }) => {
            const images = Photos.map(({ Url, Title }) => ({
              src: Url,
              caption: Title,
            }));

            return (
              <Paper key={Visit?.Id}>
                <h2>
                  {Visit?.Location?.Name} | {Visit?.Product?.Name}
                </h2>
                <PhotoGallery images={images} />
              </Paper>
            );
          })}
        </>
      ) : (
        <p>No photos recieved</p>
      )}
    </>
  );
};

export default PhotoReport;
