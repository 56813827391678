import React from "react";
import { Button } from "@redwigwam/redalerts.elements.button";
import { ChevronLeft } from "@material-ui/icons";
import { useHistory } from "react-router";

export const BackButton = ({ text = "Back" }) => {
  const history = useHistory();
  return (
    <Button
      text={text}
      startIcon={<ChevronLeft />}
      onClick={() => history.goBack()}
      secondary
    />
  );
};
