import axios from "axios";
import { authenticateUser } from "../store/auth.slice";
import { clearBasket, updateBudget } from "../store/basket.slice";
import { setUserData } from "../store/user.slice";
import { API_ENDPOINTS } from "./data.service";

let inMemoryToken = { token: null, expiry: null };
let silentRefreshTimeout;

export function login(token, expiry, dispatch) {
  if (token && expiry) {
    inMemoryToken = {
      token,
      expiry,
    };

    dispatch(authenticateUser(true));

    const timeoutDuration = new Date(expiry) - new Date();
    console.log("login - setting timeout", timeoutDuration / 1000);
    silentRefreshTimeout = setTimeout(async () => {
      await silentRefresh(dispatch);
    }, timeoutDuration);
  } else {
    console.error("[login] Unexpected parameters", {
      token,
      expiry,
    });
    logout(dispatch);
  }
}

export function logout(dispatch) {
  inMemoryToken = { token: null, expiry: null };
  console.log("logout - clear token", inMemoryToken);

  dispatch(authenticateUser(false));
  dispatch(setUserData());
  dispatch(updateBudget());
  dispatch(clearBasket());

  clearTimeout(silentRefreshTimeout);
  console.log("logout - clear timeout", silentRefreshTimeout);

  // to support logging out from all windows
  window.localStorage.setItem("logout", Date.now());
}

export async function silentRefresh(dispatch) {
  try {
    const response = await axios.post(API_ENDPOINTS.hirer.refreshToken);
    const { Message, Success, ObjData } = response?.data ?? {};
    console.log(
      `[silent refresh] - ${Success ? "Success" : "Fail"} - ${Message}`
    );
    if (Success) {
      login(ObjData?.Token, ObjData?.TokenExpireTime * 1000, dispatch);
    } else {
      logout(dispatch);
    }
  } catch (e) {
    console.error(e);
    logout(dispatch);
  }
}

export function getToken() {
  return inMemoryToken;
}
