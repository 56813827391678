import React from "react";

const CorrectedStockSystemIcon = () => (
  <svg width="48px" height="44px" viewBox="0 0 48 44" version="1.1">
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="Icons"
        transform="translate(-89.000000, -147.000000)"
        fill="#89016F"
        fillRule="nonzero"
      >
        <g
          id="icon_correctedsystem"
          transform="translate(89.000000, 147.000000)"
        >
          <g id="Material-Icons---Material-UI-19">
            <path
              d="M43.6363636,0 L4.36363636,0 C1.96363636,0 0,1.98 0,4.4 L0,30.8 C0,33.22 1.96363636,35.2 4.36363636,35.2 L19.6363636,35.2 L19.6363636,39.6 L15.2727273,39.6 L15.2727273,44 L32.7272727,44 L32.7272727,39.6 L28.3636364,39.6 L28.3636364,35.2 L43.6363636,35.2 C46.0363636,35.2 48,33.22 48,30.8 L48,4.4 C48,1.98 46.0363636,0 43.6363636,0 Z M43.6363636,30.8 L4.36363636,30.8 L4.36363636,4.4 L43.6363636,4.4 L43.6363636,30.8 Z"
              id="Shape"
            ></path>
          </g>
          <g
            id="Material-Icons---Material-UI-20-Copy"
            transform="translate(12.000000, 9.000000)"
          >
            <polygon
              id="Path"
              points="7.62706083 14.2013423 1.93746447 8.60402685 0 10.4966443 7.62706083 18 24 1.89261745 22.0761796 0"
            ></polygon>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default CorrectedStockSystemIcon;
