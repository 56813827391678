import { node } from "prop-types";

import styled from "styled-components";

import NotificationsOutlinedIcon from "@material-ui/icons/NotificationsOutlined";
import AssignmentOutlinedIcon from "@material-ui/icons/AssignmentOutlined";
import AccountCircleOutlinedIcon from "@material-ui/icons/AccountCircleOutlined";

import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../api/auth";
import { selectIsAuthenticated } from "../../store/auth.slice";
import { selectUserData } from "../../store/user.slice";

import { selectGlobalError, setGlobalError } from "../../store/error.slice";
import { Alert } from "@material-ui/lab";
import { Snackbar } from "@material-ui/core";

import Logo from "../../images/redwigwamlogo.png";
import NavLink from "../NavLink";
import { captilizeText } from "../../utils/text";

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 96px calc(100% - 96px);
  grid-template-rows: 77px auto;
  gap: 0px 0px;
  grid-template-areas:
    "corner top"
    "side main";

  width: 100%;
  height: 100%;
`;
const GridCorner = styled.div`
  grid-area: corner;
  background-color: #7f0265;
  padding: 24px;

  position: fixed;
  width: 96px;
  height: 77px;
`;
const GridTop = styled.div`
  grid-area: top;
  background-color: #e9e7e7;
  padding: 0 24px;

  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const GridSide = styled.div`
  grid-area: side;
  background-color: #89016f;
  padding: 24px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  position: fixed;
  width: 96px;
  height: calc(100vh - 77px);
  bottom: 0;

  z-index: 100;
`;

const NavTop = styled.div`
  display: flex;
  flex-direction: column;
  align-items: baseline;
`;
const NavBottom = styled.div`
  display: flex;
  flex-direction: column;
  align-items: baseline;
`;

const GridMain = styled.div`
  grid-area: main;
  background-color: #f4f3f3;
  padding: 24px 24px 0 24px;
`;

const PageTitle = styled.h1`
  margin: 0;
  color: ${(props) => props?.theme?.colours?.purple};
  font-weight: 600;
`;

const CornerLogo = styled.img`
  width: 100%;
`;

const UserAvatar = styled.div`
  text-align: right;
`;
const UserName = styled.p`
  margin: 0;
  font-size: 14px;
`;
const UserEmail = styled.p`
  margin: 0;
  color: rgba(37, 16, 16, 0.5);
  font-size: 14px;
`;

const propTypes = {
  children: node.isRequired,
};

const PageLayout = ({ title, children }) => {
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const globalError = useSelector(selectGlobalError);

  const userData = useSelector(selectUserData);

  const dispatch = useDispatch();

  const handleLogOut = () => {
    logout(dispatch);
  };

  return (
    <>
      <GridContainer className="container">
        <GridCorner className="corner">
          <Link to="/">
            <CornerLogo src={Logo} alt="Red alerts logo" />
          </Link>
        </GridCorner>
        <GridTop className="top">
          <PageTitle>{title}</PageTitle>
          {isAuthenticated && (
            <UserAvatar>
              {userData?.FullName && (
                <UserName>Hello, {captilizeText(userData?.FullName)}</UserName>
              )}
              {userData?.EmailAddress && (
                <UserEmail>{userData?.EmailAddress}</UserEmail>
              )}
            </UserAvatar>
          )}
        </GridTop>
        <GridSide className="side">
          {isAuthenticated && (
            <NavTop>
              <NavLink
                path="/"
                icon={<NotificationsOutlinedIcon />}
                title="Available Tasks"
              />
              <NavLink
                path="/OrderHistory"
                icon={<AssignmentOutlinedIcon />}
                title="Order History"
              />
            </NavTop>
          )}
          <NavBottom>
            {isAuthenticated && (
              <NavLink
                onClick={handleLogOut}
                icon={<AccountCircleOutlinedIcon />}
                title="Log Out"
              />
            )}
          </NavBottom>
        </GridSide>
        <GridMain className="main">{children}</GridMain>
      </GridContainer>
      <Snackbar
        open={globalError}
        autoHideDuration={6000}
        onClose={() => dispatch(setGlobalError())}
      >
        <Alert severity="error" onClose={() => dispatch(setGlobalError())}>
          {globalError}
        </Alert>
      </Snackbar>
    </>
  );
};

PageLayout.propTypes = propTypes;

export default PageLayout;
