import { createSelector, createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: {},
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserData: (state, action) => {
      state.data = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setUserData } = userSlice.actions;

export const selectUser = (state) => state?.user ?? {};

export const selectUserData = createSelector(
  selectUser,
  (user) => user?.data ?? {}
);

export default userSlice.reducer;
