import React, { useEffect, useState } from "react";

import Meta from "../ui-components/Meta";
import styled from "styled-components";
import { Paper } from "@redwigwam/redalerts.elements.paper";

import { BackButton } from "../ui-components/Buttons";

import Carousel from "react-multi-carousel";

import "react-multi-carousel/lib/styles.css";
import dataService from "../api/data.service";
import { useParams } from "react-router-dom";
import {
  formatDateWithTime,
  captilizeText,
  formatCurrency,
  formatROI,
} from "../utils/text";

import interventionMap from "../utils/interventions";
import EnlargingPhoto from "../ui-components/EnlargingPhoto";
import PhotoGallery from "../ui-components/PhotoGallery";

const OverviewGrid = styled.div`
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  grid-template-rows: auto auto auto;
  grid-column-gap: 16px;
  grid-row-gap: 8px;
  background-color: #eae8e8;
  border-radius: 8px;
  padding: 24px;
`;

const ResponseGrid = styled(OverviewGrid)`
  grid-template-columns: auto auto;
`;

const SummaryItemWrap = styled.div``;

const SummaryTitle = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  color: #877c7c;
`;

const SummaryText = styled.p`
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
`;

const InterventionList = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
const InterventionItemWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 96px;
  width: 240px;
  border-radius: 8px;
  margin: 0 1% 8px 0;
  overflow: hidden;
`;
const InterventionItemIcon = styled.div`
  background-color: #e7cce2;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 8px;
  > svg {
    font-size: 48px;
    color: ${(props) => props.theme.colours.purple};
  }
`;
const InterventionItemText = styled.div`
  background-color: #f4e6f1;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;

  flex-grow: 1;
  padding: 8px;
`;

const SupportingPhoto = styled.img`
  width: 240px;
  height: 240px;
  margin-right: 8px;
  border-radius: 8px;
  background-color: lightgrey;
`;

const Heading = styled.h2`
  font-weight: 600;
`;
const InterventionItem = ({ outcome }) => {
  const Icon = interventionMap.get(outcome.toLowerCase());

  return (
    <InterventionItemWrap>
      {Icon && (
        <InterventionItemIcon>
          <Icon />
        </InterventionItemIcon>
      )}
      <InterventionItemText>
        <p>{outcome}</p>
      </InterventionItemText>
    </InterventionItemWrap>
  );
};

const OverviewItem = ({ title, text, loading }) => (
  <SummaryItemWrap>
    <SummaryTitle>{title}</SummaryTitle>
    <SummaryText>{loading ? "-" : text}</SummaryText>
  </SummaryItemWrap>
);

const Alert = () => {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);

  const { orderId, alertId } = useParams();

  useEffect(() => {
    const getAlertSummary = async () => {
      try {
        setLoading(true);
        const response = await dataService.getAlertSummary(orderId, alertId);
        setData(response?.data);
        setLoading(false);
      } catch (e) {
        console.error(e);
      }
    };
    getAlertSummary();
  }, [orderId, alertId]);

  const getAddress = (location = {}) => {
    const { Address1, Address2, Address3, Town, Postcode } = location;

    return `${Address1 && `${Address1}, `}${Address2 && `${Address2}, `}${
      Address3 && `${Address3}, `
    }${Town && `${Town}, `}${Postcode && `${Postcode}`}`;
  };

  const images =
    data?.Answers?.filter((answer) => answer?.Question?.QuestionType === 1).map(
      ({ Answer, Question }) => ({ src: Answer, caption: Question?.Question })
    ) ?? [];

  const excludedQuestions = [
    "7fef7cd1-2347-40c9-8e6d-dc15e3947c61",
    "a106827a-8138-4c58-a83b-e770ffb496d2",
  ];
  const textAnswers =
    data?.Answers?.filter(
      (answer) =>
        ![1, 2].includes(answer?.Question?.QuestionType) &&
        !excludedQuestions.includes(answer?.QuestionId)
    ) ?? [];

  const interventions =
    data?.Answers?.filter((answer) => answer?.IsIntervention) ?? [];

  return (
    <>
      <Meta title="Task" />
      <BackButton />
      <Paper>
        <Heading>Overview</Heading>
        <OverviewGrid>
          <OverviewItem
            title="Retailer"
            text={captilizeText(data?.Alert?.Retailer ?? "-")}
            loading={loading}
          />
          <OverviewItem
            title="SKU"
            text={data?.Visit?.Product?.Sku ?? "-"}
            loading={loading}
          />
          <OverviewItem
            title="Value"
            text={formatCurrency(data?.Alert?.AlertValue)}
            loading={loading}
          />
          <OverviewItem
            title="Date Identified"
            text={
              data?.Visit?.DateCreated?.Seconds
                ? formatDateWithTime(data?.Visit?.DateCreated?.Seconds * 1000)
                : "-"
            }
            loading={loading}
          />
          <OverviewItem
            title="Store"
            text={data?.Visit?.Location?.Name ?? "-"}
            loading={loading}
          />
          <OverviewItem
            title="Product"
            text={data?.Visit?.Product?.Name ?? "-"}
            loading={loading}
          />
          <OverviewItem
            title="Cost"
            text={formatCurrency(data?.Alert?.AlertCost)}
            loading={loading}
          />
          <OverviewItem
            title="Date Ordered"
            text={
              data?.Visit?.DateAssigned?.Seconds
                ? formatDateWithTime(data?.Visit?.DateAssigned?.Seconds * 1000)
                : "-"
            }
            loading={loading}
          />
          <OverviewItem
            title="Address"
            text={getAddress(data?.Visit?.Location)}
            loading={loading}
          />
          <OverviewItem
            title="Task Type"
            text={data?.Visit?.VisitType}
            loading={loading}
          />
          <OverviewItem
            title="Anticipated ROI"
            text={formatROI(data?.Alert?.AnticipatedROI)}
            loading={loading}
          />
          <OverviewItem
            title="Date Completed"
            text={
              data?.Visit?.SealedDate?.Seconds
                ? formatDateWithTime(data?.Visit?.SealedDate?.Seconds * 1000)
                : "-"
            }
            loading={loading}
          />
        </OverviewGrid>
      </Paper>

      {data?.Visit?.VisitType === "Distribution" && interventions.length > 0 && (
        <Paper>
          <Heading>Interventions</Heading>
          <InterventionList>
            {interventions.map(({ Outcome, Id }) => (
              <InterventionItem key={Id} outcome={Outcome} />
            ))}
          </InterventionList>
        </Paper>
      )}

      {images.length > 0 && (
        <Paper>
          <Heading>Supporting Photographs</Heading>
          <PhotoGallery images={images} />
        </Paper>
      )}

      {textAnswers.length > 0 && (
        <Paper>
          <Heading>Responses</Heading>
          <ResponseGrid>
            {textAnswers.map(({ Question: { Question }, Answer, Id }) => (
              <OverviewItem key={Id} title={Question} text={Answer} />
            ))}
          </ResponseGrid>
        </Paper>
      )}
    </>
  );
};

export default Alert;
