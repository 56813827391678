import axios from "axios";
import { getToken } from "./auth";

export let inMemoryToken;

export const axiosInstance = axios.create({
  headers: {
    post: {
      "Content-Type": "application/json",
    },
  },
});

axiosInstance.interceptors.request.use(
  (config) => {
    const { token } = getToken();
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);
