import React from "react";
import Helmet from "react-helmet";
import { string } from "prop-types";

const propTypes = {
  title: string.isRequired,
  description: string,
};

const defaultProps = {
  description: null,
};

const Meta = ({ title, description }) => {
  return (
    <Helmet>
      <title>{title} | RedAlerts</title>
      {description && <meta name="description" content={description} />}
    </Helmet>
  );
};

Meta.propTypes = propTypes;
Meta.defaultProps = defaultProps;

export default Meta;
