import React, { useState, useEffect } from "react";

import { Table } from "@redwigwam/redalerts.table";
import { Paper } from "@redwigwam/redalerts.elements.paper";
import Meta from "../ui-components/Meta";
import { Button } from "@redwigwam/redalerts.elements.button";
import { ChevronRight } from "@material-ui/icons";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import dataService from "../api/data.service";
import { getFilterInfoItems, getSortInfoItems } from "../utils/table";
import { formatCurrency, formatDateWithTime } from "../utils/text";

const SlimButton = styled(Button)`
  margin: 0;
  padding: 0;
  display: block;
  height: unset;
  font-size: inherit;
`;

const StyledTable = styled(Table)`
  .table-wrap {
    height: 75vh;
  }
`;

const Orders = () => {
  const [orders, setOrders] = useState([]);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const history = useHistory();

  const [triggeredRefresh, setTriggeredRefresh] = useState(false);

  const [pageSize, setPageSize] = useState(20);
  const [pageIndex, setPageIndex] = useState(0);
  const [numRows, setNumRows] = useState();

  const [filterData, setFilterData] = useState();
  const [sortData, setSortData] = useState([
    {
      id: "DateOrdered",
      desc: true,
    },
  ]);

  useEffect(() => {
    const getData = async () => {
      try {
        setLoading(true);
        const response = await dataService.getAllOrders({
          pageinfo: {
            PageSize: pageSize,
            Page: pageIndex + 1,
          },
          FilterInfo: {
            Items: getFilterInfoItems(filterData),
            LinkOperator: "string",
          },
          SortInfo: getSortInfoItems(sortData),
        });

        setNumRows(response?.data?.NumRows);

        setOrders(response?.data?.Data);
        setLoading(false);
        setError(false);
      } catch (e) {
        console.error(e);
        setError(e.message);
        setLoading(false);
      }
    };

    getData();
  }, [triggeredRefresh, pageIndex, pageSize, sortData, filterData]);

  const handleOnClick = (id) => {
    if (id) {
      history.push(`/OrderHistory/${id}`);
    }
  };

  const columns = [
    {
      Header: "Date Ordered",
      accessor: "DateOrdered",
      Cell: (cell) => formatDateWithTime(cell?.value),
      disableFilters: true,
    },
    {
      Header: "Order Number",
      accessor: "OrderNumber",
    },
    {
      Header: "Purchase Order Ref",
      accessor: "Ponumber",
      Cell: (cell) => (cell?.value === "" ? "-" : cell.value),
    },
    {
      Header: "Cost",
      accessor: "TotalCost",
      Cell: (cell) => formatCurrency(cell.value),
      disableFilters: true,
    },

    {
      Header: "Booked Tasks",
      accessor: "AlertsBooked",
      disableFilters: true,
    },
    {
      Header: "Completed Tasks",
      accessor: "AlertsCompleted",
      disableFilters: true,
    },
    {
      Header: "Total Tasks",
      accessor: "NumberOfAlerts",
      disableFilters: true,
    },
    {
      id: "view",
      Cell: (cell) => (
        <SlimButton
          text="View Order Report"
          onClick={() => handleOnClick(cell.row?.original?.Id)}
          endIcon={<ChevronRight />}
        />
      ),
      disableSortBy: true,
      disableFilters: true,
      width: 200,
    },
  ];

  return (
    <>
      <Meta title="Orders" />
      <Paper>
        <StyledTable
          data={orders}
          columns={columns}
          noDataOverlayAction={() => setTriggeredRefresh((prev) => !prev)}
          loading={loading}
          error={error}
          enablePagination
          serverSidePagination
          controlledPageIndex={pageIndex}
          controlledPageSize={pageSize}
          controlledPageCount={Math.ceil(numRows / pageSize)}
          setControlledPageIndex={setPageIndex}
          setControlledPageSize={setPageSize}
          enableFiltering
          serverSideFiltering
          onFilter={setFilterData}
          enableSorting
          serverSideSorting
          onSort={setSortData}
        />
      </Paper>
    </>
  );
};

export default Orders;
