import { createSelector, createSlice } from "@reduxjs/toolkit";

const initialState = {
  items: {},
  lastOrderAlerts: [],
  purchasedAlerts: [],
  budget: undefined,
};

export const basketSlice = createSlice({
  name: "basket",
  initialState,
  reducers: {
    updateBasket: (state, action) => {
      state.items = action.payload;
    },
    removeBasketItem: (state, action) => {
      delete state.items[action.payload];
    },
    clearBasket: (state) => {
      state.items = {};
    },
    addPurchasedAlerts: (state, action) => {
      state.purchasedAlerts = [
        ...(state?.purchasedAlerts ?? []),
        ...action.payload,
      ];
    },
    clearPurchasedAlerts: (state) => {
      state.purchasedAlerts = [];
    },
    updateBudget: (state, action) => {
      state.budget = action.payload;
    },
    setLastOrderAlerts: (state, action) => {
      state.lastOrderAlerts = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  updateBasket,
  clearBasket,
  removeBasketItem,
  updateBudget,
  addPurchasedAlerts,
  clearPurchasedAlerts,
  setLastOrderAlerts,
} = basketSlice.actions;

export const selectBasket = (state) => state.basket || {};
export const selectBasketItems = createSelector(
  selectBasket,
  (basket) => basket.items
);

export const selectBasketTotal = createSelector(selectBasketItems, (items) => {
  return Object.values(items).reduce(
    (acc, { AlertCost = 0 }) => acc + AlertCost,
    0
  );
});

export const selectBasketAnticipatedROI = createSelector(
  selectBasketItems,
  (items) => {
    return Object.values(items).reduce(
      (acc, { AlertValue = 0, AlertCost = 0 }) => acc + AlertValue - AlertCost,
      0
    );
  }
);

export const selectPurchasedAlerts = createSelector(
  selectBasket,
  (basket) => basket?.purchasedAlerts ?? []
);

export const selectLastOrderAlerts = createSelector(
  selectBasket,
  (basket) => basket?.lastOrderAlerts ?? []
);

export const selectLastOrderAnticaptedROI = createSelector(
  selectLastOrderAlerts,
  (items) => {
    return Object.values(items).reduce(
      (acc, { AlertValue = 0, AlertCost = 0 }) => acc + AlertValue - AlertCost,
      0
    );
  }
);

export const selectBudget = createSelector(
  selectBasket,
  (basket) => basket?.budget
);

export default basketSlice.reducer;
