import AddToPhotosOutlinedIcon from "@material-ui/icons/AddToPhotosOutlined";
import LabelOutlinedIcon from "@material-ui/icons/LabelOutlined";
import ShoppingCartOutlinedIcon from "@material-ui/icons/ShoppingCartOutlined";
import SettingsOverscanOutlinedIcon from "@material-ui/icons/SettingsOverscanOutlined";
import WidgetsOutlinedIcon from "@material-ui/icons/WidgetsOutlined";
import CorrectedStockSystemIcon from "../images/CorrectedStockSystemIcon";

const interventionMap = new Map();

interventionMap.set("facings increased", SettingsOverscanOutlinedIcon);
interventionMap.set("capacity increased", AddToPhotosOutlinedIcon);
interventionMap.set("ordered stock", ShoppingCartOutlinedIcon);
interventionMap.set("corrected stock system", CorrectedStockSystemIcon);
interventionMap.set("merchandised stock", WidgetsOutlinedIcon);
interventionMap.set("sel sited", LabelOutlinedIcon);

export default interventionMap;
