import Alert from "../pages/Alert";
import Checkout from "../pages/Checkout";
import Confirmation from "../pages/Confirmation";
import Home from "../pages/Home";
import Login from "../pages/Login";
import Order from "../pages/Order";
import Orders from "../pages/Orders";
import PhotoReport from "../pages/PhotoReport";

const routes = [
  {
    title: "Login",
    path: "/login",
    page: Login,
  },
  {
    title: "Checkout",
    path: "/checkout",
    page: Checkout,
  },
  {
    title: "Order Confirmation",
    path: "/confirmation",
    page: Confirmation,
  },

  {
    title: "Order History",
    path: "/OrderHistory",
    page: Orders,
    exact: true,
  },
  {
    title: "Task Summary",
    path: "/OrderHistory/:orderId/Alert/:alertId",
    page: Alert,
    exact: true,
  },
  {
    title: "Photographic Evidence Report",
    path: "/OrderHistory/:orderId/Photos",
    page: PhotoReport,
    exact: true,
  },
  {
    title: "Order Details",
    path: "/OrderHistory/:orderId",
    page: Order,
    exact: true,
  },

  {
    title: "Available Tasks",
    path: "/",
    page: Home,
  },
];

export default routes;
